export const Blob_Base_Url = "https://ik.imagekit.io/up7g4bvjpofx";
export const ImageKit_Product_uri = "https://ik.imagekit.io/up7g4bvjpofx/";
export const Auth_Endpoint = "https://api-main.dealsonopenbox.com";
export const Chat_Uri = "https://deals-box-chat.vercel.app";
export const Main_Base_Url = "https://api-main.dealsonopenbox.com";
export const Base_Site = "https://dealsonopenbox.com";

export const Categories = [
  {
    title: "Phone & Comp",
    icon: "laptop",
    href: "phone_computer",
    show: true,
  },
  {
    title: "Furniture",
    icon: "furniture",
    show: true,
    class: "mt-0",
  },
  {
    title: "Garden",
    icon: "garden",
    show: false,
  },
  {
    title: "Appliances",
    icon: "appliances",
  },
  {
    title: "Electrical",
    icon: "electrical",
  },
  {
    title: "Auto",
    icon: "auto",
  },
  {
    title: "Fashion",
    icon: "fashion",
    show: true,
    class: "px-2 mt-1",
  },
  {
    title: "Bath",
    icon: "bath",
    show: true,
    class: "pt-2",
  },
  {
    title: "Decoration",
    icon: "decor",
    show: false,
  },
  {
    title: "Sports",
    icon: "sports",
    show: true,
    class: "mt-3",
  },
  {
    title: "Baby",
    icon: "baby",
    show: false,
  },
  {
    title: "Office",
    icon: "office",
  },
  {
    title: "Outdoor",
    icon: "outdoor",
  },
  {
    title: "Bed",
    icon: "bed",
    show: true,
    class: "mt-0",
  },
  {
    title: "Electronics",
    icon: "electronics",
    show: false,
  },
  {
    title: "Kitchen",
    icon: "kitchen",
    show: false,
  },
  {
    title: "Toys & Games",
    icon: "toys",
    href: "toys_games",
    show: false,
  },
  {
    title: "Household",
    icon: "household",
    show: false,
  },
  {
    title: "Other",
  },
];

export const staticImages = {
  sadGirl: "/images/sad-girl.webp",
  confused: "/images/confused.png",
  noResult: "/images/no-result.webp",
};
